import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { checkExpriedPromotion, mapNodesToData } from '../utils/helpers';
import loadable from '@loadable/component';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Seo from '../components/common/Seo';
import { getSrc } from 'gatsby-plugin-image';
import ItemListSchema from '../components/common/ItemListSchema';
import { useStore } from '../hooks';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const ArchiveProduct = loadable(() => import('../components/archive-product'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicBrand: {
      data: { meta_title, meta_description, banner },
    },
    allShopifyProduct,
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    hideScript: true,
    location,
  };

  return (
    <Seo {...seo}>
      <ItemListSchema products={allShopifyProduct?.nodes || []} />
      {banner?.gatsbyImageData && <link rel="preload" as="image" href={getSrc(banner)} />}
    </Seo>
  );
};

const BrandProduct = (props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const { rules } = useStore();
  const { fgcCollections, allShopifyProduct, prismicBrand, subBrands } = data;

  const [state, setState] = useState({
    dataPage: prismicBrand,
    products: allShopifyProduct?.nodes || [],
    loading: true,
  });

  useEffect(() => {
    prismicBrand.data.subcategories = mapNodesToData(subBrands);

    const objProducts = {},
      objManual = {};

    allShopifyProduct?.nodes?.forEach(
      (v) => (objProducts[v.shopifyId] = { ...v, best_sellers: 500 })
    );

    fgcCollections?.manual?.map(({ id }, i) => {
      const product = objProducts?.[id];
      if (product) {
        objManual[id] = product;
        delete objProducts[id];
      }
    });

    fgcCollections?.best_sellers?.map(({ id }, i) => {
      const product = objManual?.[id];
      if (product) objManual[id] = { ...product, best_sellers: i };
    });

    const products = Object.values({ ...objManual, ...objProducts });

    setState({ dataPage: prismicBrand, products, loading: false });
  }, []);

  const promotion =
    data?.promotion &&
    data?.promotion?.data?.promotion_widget?.length > 0 &&
    !checkExpriedPromotion(rules, data?.promotion?.prismicId)
      ? data?.promotion?.data?.promotion_widget[0]
      : {};

  return (
    <Layout location={location}>
      {/* <h1 hidden>{h1_title}</h1> */}
      <ArchiveProduct isBrand={true} state={state} promotion={promotion} />
    </Layout>
  );
};

export default withPrismicPreview(BrandProduct);

export const query = graphql`
  query ($uid: String!, $url: String!, $title: [String]!, $promotion_uids: [String]) {
    prismicBrand(uid: { eq: $uid }) {
      uid
      data {
        name
        url
        default_sort
        bottom_description {
          richText
          text
        }
        h1_title
        meta_description
        meta_title
        parent_brand {
          brand {
            document {
              ... on PrismicBrand {
                data {
                  name
                  url
                }
              }
            }
          }
        }
        display
        banner {
          gatsbyImageData
          alt
          thumbnails {
            mobile {
              gatsbyImageData
              alt
            }
          }
        }
        display_activeskin
        banner_activeskin {
          gatsbyImageData(breakpoints: 895, layout: FULL_WIDTH)
          alt
        }
        start_date
        end_date
        heading
        sub_heading
        background_colour
        theme
        logo {
          gatsbyImageData(breakpoints: 200)
          alt
        }
      }
    }

    promotion: prismicRule(data: { brand: { uid: { in: $promotion_uids } } }) {
      prismicId
      data {
        start_date
        end_date
        promotion_widget {
          apply_to_child
          title
          sub_title
          tag
          image {
            gatsbyImageData(breakpoints: 280)
            alt
            thumbnails {
              lifestyle {
                gatsbyImageData(breakpoints: 280)
              }
            }
          }
          button_text
          button_link {
            handle
          }
        }
      }
    }

    subBrands: allPrismicBrand(
      filter: {
        data: {
          parent_brand: { elemMatch: { brand: { uid: { eq: $uid } } } }
          url: { regex: $url }
          hide_category: { ne: true }
        }
      }
    ) {
      nodes {
        data {
          name
          url
        }
      }
    }

    fgcCollections(title: { in: $title }) {
      manual {
        id
      }
      best_sellers {
        id
      }
    }

    allShopifyProduct(
      filter: {
        collections: { elemMatch: { title: { in: $title } } }
        priceRangeV2: { maxVariantPrice: { amount: { ne: "0.0" } } }
      }
    ) {
      nodes {
        id
        title
        handle
        legacyResourceId
        shopifyId
        totalInventory
        productType
        collections {
          title
        }
        hasOutOfStockVariants
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        images {
          shopifyId
          gatsbyImageData(width: 280, formats: WEBP, breakpoints: 280)
          altText
          originalSrc
        }
        variants {
          id
          storefrontId
          price
          compareAtPrice
          sku
          shopifyId
          selectedOptions {
            name
          }
        }
        vendor
        createdAt
      }
    }
  }
`;
